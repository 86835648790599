import React from "react";
import { supportedChainsAtom, useAtom } from "~/atoms";

export function useSupportedChains() {
  const [supportedChains, setSupportedChains] = useAtom(supportedChainsAtom);

  React.useEffect(() => {
    if (supportedChains.length === 0) {
      fetch("/api/supported-chains")
        .then((res) => res.json())
        .then((res) => {
          setSupportedChains(res.supportedChains);
        })
        .catch((err) => console.error(err));
    }
  }, [setSupportedChains, supportedChains.length]);

  const memoizedResult = React.useMemo(
    () => ({
      supportedChains,
    }),
    [supportedChains],
  );

  return memoizedResult;
}
